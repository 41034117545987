.languag-dropdown {
    position: absolute;
    top: 70%;
    right: -5px;
    z-index: 99999;
    display: block;
    float: left;
    /* min-width: 1.4375rem; */
    padding:.81rem 0;
    margin: 0.5rem 0 0;
    font-size: 1rem;
    color: #677788;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.15);
    border-radius: 0.3125rem;
    /* border-top: 0.1875rem solid #377dff; */
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
    cursor: pointer;
}
.pointer {
    cursor: pointer;
}
.languag-dropdown :first-child {
    padding-top: 0.2rem;
}

/* @media only screen and (max-width: 601px) {
    .languag-dropdown {
        z-index: 0;
        display: contents;
    }
    .languag-dropdown .dropdown-item {
        display: inline-block;
        width: 50%;
    }
} */
.img-profile {
    height: 2rem;
    width: 2rem;
}
.dropdown-item-image {
    height: 1.5rem;
    width: 1.5rem;
    /* height: 2rem;
    width: 2rem; */
}

.dropdown-item {
    /* vertical-align: middle;
    display: table-cell; */
    /* height: 2rem;
    width: 2rem; */
    display: flex;
    align-items: center;
    padding: 0.5rem .9rem;
    /* justify-content: center; */
}

.dropdown-toggle::after {
    content: '\f107';
}