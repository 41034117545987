.form-container {
    height: 80%;
    align-content: center;
    display: grid;
}
.logo {
    height: 10%;
}

a {
    color: slategrey;
    text-decoration: dotted;
}

.down-button {
    display: flex;
    align-items: flex-end;
}
.h-100 {
    min-height: max-content !important;
}
.dropbtn {
    /* background-color: #3498db; */
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

.dropup {
    position: relative;
    display: inline-block;
}

.dropup-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 100px;
    bottom: 20px;
    border-radius: 6px;
    z-index: 1;
    border: 2px solid #eee;
    box-shadow: 3px 2px 17px -6px;
}

.dropup-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropup-content a:hover {
    background-color: #ccc;
}

.dropup:hover .dropup-content {
    display: block;
}
.arrow-up::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f106';
    font-family: 'Font Awesome 5 Free';
}