.card-hover {
    min-width: 100px;
    height: auto;
    overflow: hidden;
    border-radius: 15px;
    margin: 1rem 0.5rem;
    padding: 20px 20px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
    transition: 0.5s;
}
.card-hover:hover {
    /* transform: scale(1.04); */
    background-color: #4e73df;
    color: white;
    cursor: pointer;
}
.disabled:hover {
    background-color: #ebebe4;
    color: unset;
    cursor: initial;
}
.disabled {
    /* transform: scale(1.04); */
    background-color: #ebebe4;
    cursor: initial;
}
.current:hover {
    background-color: #66df4e;
    color: white;
    cursor: initial;
}
.current {
    /* transform: scale(1.04); */
    background-color: #66df4e;
    color: white;
    cursor: initial;
}

.card-hover:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: rgba(255, 255, 255, 0.1);
    z-index: 1;
    transform: skewY(-5deg) scale(1.5);
}

.title .fa {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}
.title h2 {
    position: relative;
    margin: 20px 0 0;
    padding: 0;
    z-index: 2;
}
.price {
    position: relative;
    z-index: 2;
}
.price h4 {
    margin: 0;
    padding: 20px 0;
}
.option {
    position: relative;
    z-index: 2;
}
.option ul {
    margin: 0;
    padding: 0;
}
.option ul li {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
}
.option ul li svg {
    margin-right: 5px;
    padding: 0;
    font-weight: bold;
    color: "black";
}
.card-hover a {
    display: block;
    position: relative;
    z-index: 2;
    background-color: #fff;
    color: #262ff0;
    width: 150px;
    height: 40px;
    text-align: center;
    margin: 20px auto 0;
    line-height: 40px;
    border-radius: 40px;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
