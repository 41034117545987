body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: small;
    position: absolute;
    /* Header Height  */
    /* top: 40px; */
    /* Footer Height  */
    /* bottom: 20px; */
    width: 100%;
    margin: 0;
    font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #858796;
    text-align: left;
    background-color: #fff;
}
#root {
    /* position: absolute;
    top: 40px;  Header Height 
    bottom: 20px;  Footer Height 
    width: 100%; */
    height: 100%;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.h-10 {
    height: 10%;
}
.h-5 {
    height: 5%;
}
.h-15 {
    height: 15%;
}
.h-20 {
    height: 20%;
}
.h-30 {
    height: 30%;
}
.h-45 {
    height: 45%;
}
.h-70 {
    height: 70%;
}
.h-90 {
    height: 90%;
}
.w-15 {
    width: 15%;
}
.w-30 {
    width: 30%;
}
.w-70 {
    width: 70%;
}
.w-20 {
    width: 20%;
}
.w-45 {
    width: 45%;
}
.w-10 {
    width: 10%;
}
.w-90 {
    width: 90%;
}
