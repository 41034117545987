/* #backgroundimage {
    height: 100px;
    right: 0;
    margin: 0;
    min-height: 100%;
    min-width: 674px;
    padding: 0;
    position: fixed;
    top: 0;
    width: 100%;
} */
.img {
    height: auto;
    width: 100%;
    border-radius: 10px;
    border: 2px solid #eee;
    box-shadow: 5px 10px 15px 0px;
}
.img-container {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    flex-grow: 4;
    transition: 0.8s;
    /* position: absolute;
    top: 0;
    left: 0;
    z-index: 0; */
}
.img-container:hover {
    transform: scale(1.04);
    transition: 0.8s;
    /* background-color: #4e73df;
    color: white; */
    cursor: pointer;
}
.cont {
    flex-grow: 1;
}
.cards {
    z-index: 2;
    position: relative;
}
.imgContainer {
    height: 100%;
}
.bgimg {
    /* backgroundImage: "url(/undraw_thought.svg)", */
    /* background-color: #377dffcc; */
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
}
.bgText {
    font-size: 6em;
    text-shadow: 2px 2px #000;
    background-color: #00000011;
    border-radius: 10px;
}
